/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

              var parts = window.location.href.split('/');
              var urlLastParameter = parts.pop() || parts.pop();  // handle potential trailing slash

              // Collapse dropdown menu on entering page
              if (urlLastParameter === 'begrafenis') {
                $('.js-children').eq(0).css('display', 'block');
              } else if (urlLastParameter === 'crematie') {
                $('.js-children').eq(1).css('display', 'block');
              } else if (urlLastParameter === 'greenleave-uitvaart') {
                $('.js-children').eq(2).css('display', 'block');
              }

                $('.js-show-sub').on('click', function () {
                    $(this).toggleClass('bottom');
                    $(this).siblings('.js-children').slideToggle();
                });

                $(".js-search").on('click', function () {
                    $(".primary-menu").addClass("search-box-active");
                });

                $(".fa-close").on('click', function () {
                    $(".primary-menu").removeClass("search-box-active");
                });


                $(".js-sticky-cta-header").on('click', function () {
                    $(".sticky-cta-content").slideToggle();
                    $(".js-icon-arrow").toggleClass("icon-arrow--down");
                });


                $(".sticky-cta-header").click(function () {
                    $(".fa-chevron-down").toggleClass('flip');
                });


                var menuBtn = $(".js-menu-btn");


                menuBtn.on('click', function () {
                    $(this).toggleClass("is-active");
                    $(document.body).toggleClass("menu-active");
                });

                var menuBtntest = $('.js-menu');

                menuBtntest.click(function () {
                    $(".menu-class").slideToggle();
                });

                $(".js-dropdown-click").click(function () {
                    var content = $(this).children('.dropdown-content');
                    if (content.hasClass("open")) {
                        content.removeClass("open");
                    } else {
                        $(".dropdown-content").removeClass("open");
                        content.addClass("open");
                    }
                });

                $(".js-dropdown-cart").click(function () {
                    $(this).toggleClass("is-active");
                    return false;
                });

                $(".button-choose-category").click(function () {
                    $(".webshop-category-list").slideToggle();
                    return false;
                });

                $(".button-shop--filter").click(function () {
                    $('.shop-filter-wrapper').css('display', 'block');
                    $(".shop-filter--content").css('display', 'block');
                    $('.shop-filter--close').css('display', 'inline-block');
                    $("body").addClass("no-scroll");
                });

                $(".shop-filter__close").click(function () {
                    $(this).css("display", "none");
                    $(".shop-filter__content").css('display', 'none');
                    $('.shop-filter-wrapper').css('display', 'none');
                    $("body").removeClass("no-scroll");
                });

                $(".shop-category-item--arrow-wrapper").click(function () {
                    $(this).parent().find(".webshop-category-list--extend").toggleClass("extended");
                    $(this).children(".shop-category-item--arrow").toggleClass("rotate90");
                });

                $(".js-filter-dropdown").click(function () {
                    $(this).find(".shop-filter-sub-list").slideToggle();
                });

                $(".product-option__toggle-button").click(function () {
                    var checkbox = $(this).children('input[type="checkbox"]');
                    checkbox.prop('checked', !checkbox.prop('checked'));
                    $(this).parent().find(".product-option__form").slideToggle();
                });

                $(".product-info__item").click(function () {
                    var className = this.className.replace("product-info__item ", "");
                    switch (className) {
                        case "info-description":
                            $(".info-extend-description").toggle();
                            break;
                        case "info-extra-info":
                            $(".info-extend-extra-info").toggle();
                            break;
                        case "info-measure":
                            $(".info-extend-measure").toggle();
                            break;
                    }
                    $(this).find(".product-info__plus").toggleClass("fa-minus");
                });

                /*   $('.js-slick-slider').slick({
                       infinite: false,
                       speed: 300,
                       slidesToShow: 5,
                       slidesToScroll: 5,
                       responsive: [
                           {
                               breakpoint: 767,
                               settings: {
                                   slidesToShow: 2,
                                   slidesToScroll: 2,
                                   infinite: true,
                               }
                           },
                       ]
                   });*/

              // Prevent multiple clicks with ContactForm 7
              function preventDoubleFormSubmits () {
                console.log("Hmm0");
                $(document).on('click', '.wpcf7-submit', function (e) {
                  console.log("Hmm1");
                  if ($('.ajax-loader').hasClass('is-active')) {
                    console.log("Hmm2");
                    e.preventDefault();
                    return false;
                  }
                });
              }
              preventDoubleFormSubmits();


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// Custom Javascript
// jQuery(document).ready(function( $ ) {

// });